<script>
import  EZUIKit from 'ezuikit-js';
export default {
    name: 'monitor',
    data() {
        return{
            player:null,
        }
    },
    created() {
        this.getVideoData()
    },
    methods:{
        getVideoData(){
            this.player = new EZUIKit.EZUIKitPlayer({
                id: 'video-container', // 视频容器ID
                accessToken: 'at.3zmgqa9b7runfpwmblkdpnlz2swmgg1q-3k86i5qeyl-1gsqeyr-mjkj3bloj',
                url: 'ezopen://open.ys7.com/FM5907723/8.hd.live',
                audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                autoplay: true,
            })
        },
    },
    beforeDestroy() {
        this.player && this.player.stop() //销毁并停止直播视频
    }
}
</script>

<template>
    <div>
        <div id="video-container"></div>
    </div>
</template>

<style scoped lang="scss">
#video-container {
    width: 100%;
    height: 80vh;
}
</style>
